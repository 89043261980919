import React from "react";

import "./index.scss";

const Achivements = ({ data }) => {
  return (
    <div className="bg-primary py-10 lg:py-12 global-x-spacing">
      <div className="achievements-grid lg:px-20">
        <div className="border-b lg:border-b-0 lg:border-r border-primary pb-10 lg:pb-0 lg:pr-20">
          <h3 className="font-medium text-center text-primary mb-10">{data.primaryStockTitle}</h3>
          <img src={data.primaryStockImage.file.url} className="w-32 mx-auto" />
        </div>
        <div className="achievements-grid-right pt-10 lg:pt-0 lg:pl-20">
          <h3 className="font-medium text-primary">{data.issuedSharedCapitalHeading}</h3>
          <h3 className="font-medium text-primary">{data.issuesSharedCapital}</h3>
          <h3 className="text-primary">{data.optionsHeading}</h3>
          <h3 className="text-primary">{data.options}</h3>
          <h3 className="text-primary">{data.warrantsHeading}</h3>
          <h3 className="text-primary">{data.warrants}</h3>
          <h3 className="text-primary">{data.dsuAndRsuHeading}</h3>
          <h3 className="text-primary">{data.dsuAndRsu}</h3>
          <h3 className="font-medium text-primary">{data.fullyDilutedCommonSharesHeading}</h3>
          <h3 className="font-medium text-primary">{data.fullyDilutedCommonShares}</h3>
        </div>
      </div>
    </div>
  );
};

export default Achivements;
